import React from 'react';
import { NextPage } from 'next';
import base64 from 'base-64';
import LoginPage from '../containers/LoginPage';
import LoginLayout from '../components/layout/login/LoginLayout';
import { ValidateUser } from '../interfaces/login';

interface Props {
  selectedUser?: ValidateUser;
}

const Login: NextPage<Props> = ({ selectedUser }: Props) => (
  <LoginLayout
    title="Sign In"
    metaDescription="Sign into your account. Don’t have an account? Create a free account today."
  >
    <LoginPage user={selectedUser} />
  </LoginLayout>
);

Login.getInitialProps = async (context) => {
  const { params }: { params?: string } = context.query;
  if (params) {
    const selectedUser = {
      ...JSON.parse(base64.decode(params)),
    };
    return { selectedUser };
  }
  return {};
};

export default Login;
